<template>
  <v-container class="division_bar">
    <v-row>
      <v-col cols="8">
        <h1 class="System_admin_text_division ml-2">System Users</h1>
      </v-col>

      <v-col cols="4" class="flex-end d-flex">
        <v-btn
          v-if="canpost === 'true'"
          color="primary"
          dark
          @click="dialog = true"
          class="header-add-location mr-2"
          ><i class="fa fa-plus mr-1" aria-hidden="true"></i>
          ADD
        </v-btn>
        <b-button @click="exportToExcel" variant="outline-primary">
          Export to Excel
        </b-button>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4" class="pt-0 pb-0">
        <v-text-field
          label="Search"
          v-model="searchText"
          clearable
          @click:clear="clearSearch"
          append-icon="mdi-magnify"
          @keyup.enter="getData"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="2">
        <v-btn outlined rounded @click="getData">Search</v-btn>
      </v-col>
    </v-row>

    <v-col cols="12" class="customer-search-table">
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1 cursor-pointer"
      >
        <template v-slot:item.uid="props">
          <v-edit-dialog
            :return-value.sync="props.item.uid"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.uid }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="UID"
                v-model="props.item.uid"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.employee="props">
          <v-edit-dialog
            :return-value.sync="props.item.employee"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.employee }}
            <template v-slot:input>
              <v-autocomplete
                class="v-input--hide-details"
                label="Employee Name"
                v-model="props.item.employee_id"
                :items="list_employee"
                item-value="id"
                item-text="text"
              ></v-autocomplete>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.user_type="props">
          <v-edit-dialog
            :return-value.sync="props.item.user_type"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.user_type }}
            <template v-slot:input>
              <v-select
                class="v-input--hide-details"
                label="User Access Level"
                v-model="props.item.user_type_id"
                :items="list_usertype"
                item-value="id"
                item-text="text"
              ></v-select>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.active="props">
          <v-edit-dialog
            :return-value.sync="props.item.active"
            @save="saveEditDialog(props.item)"
          >
            {{ props.item.active }}
            <template v-slot:input>
              <v-text-field
                class="v-input--hide-details"
                label="Active?"
                v-model="props.item.active"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="canput === 'true'"
            color="warning"
            small
            class="mr-5"
            @click="editedItems(item)"
            >fas fa-edit</v-icon
          >
          <v-icon
            v-if="candelete === 'true'"
            color="error"
            small
            @click="deleteItem(item.id)"
            >fas fa-trash-alt</v-icon
          >
        </template>
      </v-data-table>
    </v-col>

    <v-row>
      <v-col cols="12">
        <div class="header-add-systemAdmin" style="float: right">
          <router-link :to="'/SystemAdmin'"><span>Back</span></router-link>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="600" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="close">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>
                  {{
                    editedItem.id
                      ? "Update System Users Record"
                      : "Add System Users Record"
                  }}
                </h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <v-row class="pr-10 pl-10">
                        <v-col cols="12" sm="12">
                          <v-row>
                            <v-col cols="12" md="3" class="pt-0 pb-0">
                              <v-text-field
                                v-model="editedItem.uid"
                                label="UID"
                                :rules="[
                                  (v) => !!v || 'This field is required',
                                ]"
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-text-field
                                v-model="editedItem.first_name"
                                label="First Name"
                                :rules="[
                                  (v) => !!v || 'This field is required',
                                ]"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5" class="pt-0 pb-0">
                              <v-text-field
                                v-model="editedItem.last_name"
                                label="Last Name"
                                :rules="[
                                  (v) => !!v || 'This field is required',
                                ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="6" class="pt-0 pb-0">
                              <v-select
                                item-text="text"
                                item-value="id"
                                v-model="editedItem.user_type_id"
                                label="Access Level"
                                :rules="[
                                  (v) => !!v || 'This field is required',
                                ]"
                                :items="list_usertype"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" md="6" class="pt-0 pb-0">
                              <v-text-field
                                label="Password"
                                v-model="editedItem.django_pwd"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" class="pt-0 pb-0">
                              <v-text-field
                                label="Company"
                                v-model="editedItem.company"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" class="pt-0 pb-0">
                              <v-text-field
                                label="Email"
                                v-model="editedItem.email"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-text-field
                                label="Phone"
                                v-model="editedItem.phone"
                                outlined
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-text-field
                                label="Text"
                                v-model="editedItem.text_no"
                                outlined
                              ></v-text-field>
                            </v-col>

                            <v-col cols="12" md="4" class="pt-0 pb-0">
                              <v-text-field
                                label="Verify Method"
                                v-model="editedItem.verify_method"
                                outlined
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <v-row>
                            <v-col cols="12" md="12" class="pt-0 pb-0">
                              <v-checkbox
                                label="Active"
                                v-model="editedItem.active"
                                :true-value="true"
                                :false-value="false"
                              ></v-checkbox>
                            </v-col>
                          </v-row>

                          <v-row> </v-row>
                        </v-col>
                      </v-row>

                      <v-col cols="12">
                        <div class="text-right">
                          <v-btn color="primary" dark @click="saveFormData">
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <span>Confirm Delete</span>
        </v-card-title>
        <v-card-text class="subtitle-1 pa-5">
          <span>Are you sure you want to delete this item?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Cancel</v-btn>
          <v-btn text @click="deleteItemConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import XLSX from "xlsx";

export default {
  data() {
    return {
      componentURL: "Users",
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      valid: false,
      dialog: false,
      dialogDelete: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",

      headers: [
        { text: "Actions", value: "actions", sortable: false },
        { text: "UID", value: "uid", sortable: true },
        { text: "First Name", value: "first_name", sortable: true },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "User Access Level", value: "user_type", sortable: true },
        { text: "Active?", value: "active", sortable: true },
      ],

      items: [],

      list_employee: [],
      list_usertype: [],

      editedItem: {
        uid: "",
        first_name: "",
        last_name: "",
        company: "",
        phone: "",
        text_no: "",
        verify_method: "",
        django_pwd: "",
        user_type_id: "",
        user_type: "",
        active: true,
      },
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },

    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.checkPermissions();

    CommonServices.listWithParams(this.componentURL, this.items)
      .then((response) => {
        this.items = response.results;
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("Employee")
      .then((res) => {
        console.log("CODES.res.data", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_employee.push({ id: code.id, text: code.full_name })
        );
      })
      .catch((err) => {
        console.log(err);
      });

    CommonServices.getList("PermissionLevels")
      .then((res) => {
        console.log("User Types: ", res);
        let codes = res.results;
        codes.map((code) =>
          this.list_usertype.push({
            id: code.permission_level_id,
            text: code.permission_level,
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    exportToExcel() {
      // On Click Excel download button
      // export json to Worksheet of Excel
      var reportWS = XLSX.utils.json_to_sheet(this.items);
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheets to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, reportWS, "Users"); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, "Users.xlsx");
    },

    saveEditDialog(item) {
      CommonServices.updateData(this.componentURL, item).then((response) => {
        const index = this.items.findIndex((item) => item.id === response.id);
        this.$set(this.items, index, response);
      });
      this.snackbar = true;
      this.snackColor = "success";
      this.snackText = "Record successfully updated";
    },

    saveFormData() {
      if (this.$refs.form.validate()) {
        if (this.editedItem.id) {
          CommonServices.updateData(this.componentURL, this.editedItem)
            .then((response) => {
              const index = this.items.findIndex(
                (item) => item.id === response.id
              );
              this.$set(this.items, index, response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully updated";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.response.data).forEach((key) => {
                  this.snackText = error.response.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        } else {
          CommonServices.addRecord(this.componentURL, this.editedItem)
            .then((response) => {
              this.items.push(response);
              this.snackbar = true;
              this.snackColor = "success";
              this.snackText = "Record successfully added";
            })
            .catch((error) => {
              this.snackbar = true;
              this.snackColor = "error";
              try {
                Object.keys(error.data).forEach((key) => {
                  this.snackText = error.data[key][0];
                });
              } catch (e) {
                this.snackText = "Something went wrong";
              }
            });
        }
        this.close();
      }
    },

    getData() {
      this.loading = true;
      let params = this.params ? this.params : {};
      if (this.search !== "") params["search"] = this.searchText;
      CommonServices.listWithParams(this.componentURL, { params: params })
        .then((response) => {
          this.items = response.results;
          if (this.exportRecord) this.exportdata();
        })
        .catch((error) => {
          this.snackbar = true;
          this.snackColor = "error";
          this.error = "Something went wrong";
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    deleteItem(id) {
      this.deletedId = id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      CommonServices.deleteRecord(this.componentURL, this.deletedId)
        .then(() => {
          let index = this.items.findIndex(
            (item) => item.id === this.deletedId
          );
          this.items.splice(index, 1);
          this.snackbar = true;
          this.snackColor = "success";
          this.snackText = "Record successfully deleted";
        })
        .catch(() => {
          this.snackbar = true;
          this.snackColor = "error";
          this.snackText = "Something went wrong";
        });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    editedItems(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    clearSearch() {
      this.searchText = "";
      this.getData();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },
  },
};
</script>

<style scoped>
.flex-end {
  justify-content: flex-end;
}

.header-add-division {
  text-align: right;
  margin-left: 0%;
}

.header-add-division button {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #20e0a5;
  box-shadow: none !important;
  color: #fff !important;
  padding: 8px 12px !important;
  border-radius: 60px;
}

.header-add-division:focus,
.header-add-division button:focus {
  outline: none !important;
}

.headline {
  background-color: red;
  color: white;
}

.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}
.close-popup .v-image__image {
  width: 20px !important;
  object-fit: contain !important;
  background-size: contain !important;
}
.popup-wrap {
  padding: 40px 0;
}

.popup-title {
  padding: 0 50px;
  text-align: center;
}

.popup-title h6 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 37px;
  color: #000000;
}

.close-popup.v-btn {
  position: absolute;
  right: 30px;
  top: 30px;
  padding: 0 !important;
}

.close-popup.v-btn > span + span {
  display: none !important;
}

.close-popup.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 !important;
  height: auto;
  width: auto;
  min-width: auto;
}

.close-popup.v-btn:before {
  content: none;
}
@media only screen and (max-width: 768px) {
  .header-add-division {
    margin-left: 0 !important;
  }
}
</style>
